import React from "react";
import { PageProps } from "../../interfaces/projects";
import ProhectsHero from "../Shared/ProjectsHero";
import ProjectsHighlights from "../Shared/ProjectsHighlights";
import ProjectsAmenities from "../Shared/ProjectsAmenities";
import ProjectsUbicacion from "../Shared/ProjectsUbicacion";
import ProjectsSearchProperties from "../Shared/ProjectsSearchProperties";
import ProjectsFinanciacion from "../Shared/ProjectsFinanciacion";
import ProjectBrouchure from "../Shared/ProjectsBrouchure";

const SetPalermoPage: React.FC<PageProps> = ({ colors, projectName }) => {
  return (
    <div className="set-palermo-page">
      <ProhectsHero
        imgs={[
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681858283/Codevelopers/web/Set%20Palermo/L_4_atnlk8.png",
            label: "Diseño instagrameable",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681857425/Codevelopers/web/Set%20Palermo/1_odzqw7.png",
            label: "Cocinas contemporáneas",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681857496/Codevelopers/web/Set%20Palermo/B_4_shn8qu.png",
            label: "Fully equipped",
          },
        ]}
      />
      <ProjectBrouchure
        title="UN PROYECTO<br/>ALL READY"
        subtitle="Unidades de 1 y 2 ambientes<br/>  totalmente amuebladas y <br/> equipadas en Palermo Soho. <br/> listas para comenzar a rentabilizar <br/> tu inversión."
        brouchureSrc={""}
        brouchureSrcMobile={""}
        colors={colors}
        images={{
          picture: "",
          main: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681858899/Codevelopers/web/Set%20Palermo/A_wtyc7r.png",
          primary:
            "https://res.cloudinary.com/mosconi-digital/image/upload/v1681858765/Codevelopers/web/Set%20Palermo/03_6_-_Photo_ycrrrr.jpg",
          last: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681858283/Codevelopers/web/Set%20Palermo/L_4_atnlk8.png",
          background: `/${projectName}/${projectName}-logo-simple-grey.svg`,
        }}
      />
      <ProjectsSearchProperties
        colors={colors}
        project={projectName}
        images={[
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681858959/Codevelopers/web/Set%20Palermo/A_1_fgnwfp.png",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681858995/Codevelopers/web/Set%20Palermo/B_3_gkibol.png",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681859014/Codevelopers/web/Set%20Palermo/2_j8ahs5.png",
          },
        ]}
        searchForm={{
          orientacion: {
            title: "Orientación",
            options: [
              {
                label: "Frente",
                value: "frente",
              },
              {
                label: "Contrafrente",
                value: "contrafrente",
              },
              {
                label: "Piso",
                value: "piso",
              },
              {
                label: "Todas las orientaciones",
                value: "all",
              },
            ],
          },

          exterior: {
            title: "Espacio exterior",
            options: [
              {
                label: "Patio",
                value: "patio",
              },
              {
                label: "Balcón",
                value: "balcón",
              },
              {
                label: "Balcón Francés",
                value: "balcón francés",
              },
              {
                label: "Patio terraza",
                value: "Patio terraza",
              },
              {
                label: "Balcón terraza",
                value: "balcón terraza",
              },
              {
                label: "Todas los espacios",
                value: "all",
              },
            ],
          },

          ambientes: {
            title: "Cantidad de ambientes",
            options: [
              {
                label: "1 Ambiente",
                value: "1",
              },
              {
                label: "2 Ambientes",
                value: "2",
              },
              {
                label: "3 Ambientes",
                value: "3",
              },
              {
                label: "Todos los ambientes",
                value: "all",
              },
            ],
          },
        }}
      />
      <ProjectsAmenities
        title={
          "LOS AMENITIES Y ESA PLACENTERA SENSACIÓN<br />DE ESTAR DONDE UNO QUIERE ESTAR"
        }
        amenities={[
          {
            icon: "coffee",
            image: "",
            video: {
              url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1681862883/codevelopers/set-palermo/2._COFFEE_aic5yk_srxkas.mp4",
              poster:
                "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681863720/Codevelopers/web/Set%20Palermo/2_mipdia_qojxof.jpg",
            },
            title: "Coffee",
            description: "",
          },
          {
            icon: "coworking",
            image: "",
            video: {
              url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1681862339/codevelopers/set-palermo/1.COWORKING_bstksi_2_adal3k.mp4",
              poster:
                "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681863722/Codevelopers/web/Set%20Palermo/1_nq5xz0_wae2t6.jpg",
            },
            title: "Coworking",
            description: "",
          },
          {
            icon: "laundry",
            image: "",
            video: {
              url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1681862547/codevelopers/set-palermo/LAUNDRY_aj04qe_m2v5sc.mp4",
              poster:
                "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681863720/Codevelopers/web/Set%20Palermo/3_qtfqff_rafyqa.jpg",
            },
            title: "Laundry",
            description: "",
          },
          {
            icon: "guarderia-de-equipaje",
            image: "",
            video: {
              url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1681862637/codevelopers/set-palermo/GUARDERIA_VALIJA_dpswjo_kqomfh.mp4",
              poster:
                "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681863720/Codevelopers/web/Set%20Palermo/4_gyt9sv_ptirno.jpg",
            },
            title: "Guardería de<br />equipaje",
            description: "",
          },
          {
            icon: "bike-rental",
            image: "",
            video: {
              url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1681862726/codevelopers/set-palermo/BICIS_qziwob_tsxitm.mp4",
              poster:
                "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681863722/Codevelopers/web/Set%20Palermo/5_puvbyg_mwo5po.jpg",
            },
            title: "Bike<br/>rental",
            description: "",
          },
          {
            icon: "delivery-a-la-habitacion",
            image: "",
            video: {
              url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1681862776/codevelopers/set-palermo/DELIVERY_ztetvq_a7ufrn.mp4",
              poster:
                "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681863721/Codevelopers/web/Set%20Palermo/6_jwneeq_nwnm4r.jpg",
            },
            title: "delivery a la<br/>habitación",
            description: "",
          },
        ]}
        colors={colors}
      />
      <ProjectsHighlights
        colors={colors}
        items={[
          {
            title: "Nada de qué ocuparse",
            description:
              'Unidades "fully equipped" listas para ofertar en Airbnb y comenzar a rentabilizar tu inversión. Operador disponible para gestionar los alquileres, desde la publicación hasta la recepción de los huéspedes.',
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681859407/Codevelopers/web/Set%20Palermo/C_bs9lni.png",
            sustainableDevelopment: false,
            airbnb: true,
          },
          {
            title: "Diseño flexible",
            description:
              "Unidades equipadas para distintas experiencias, sillones cama para optimizar la cantidad de huéspedes. ",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1681859364/Codevelopers/web/Set%20Palermo/01_1_upowzh.png",
            sustainableDevelopment: false,
            airbnb: false,
          },
        ]}
      />
      <ProjectsUbicacion
        title="EN EL CORAZÓN DE PALERMO SOHO,<br />UN BARRIO CON ESPÍRITU COSMOPOLITA"
        description="Bares especializados, restaurantes de moda,<br />casas de diseño y galerías de arte, son parte de la propuesta que atrae a<br />turistas de todo el mundo que buscan vivir la experiencia Buenos Aires."
        colors={colors}
        image={
          "https://res.cloudinary.com/mosconi-digital/image/upload/v1681859384/Codevelopers/web/Set%20Palermo/Foto_de_Palermo_ytajo4.jpg"
        }
        location="Gurruchaga 1251, Palermo Soho, Buenos Aires"
        geo={{
          lat: -34.591809274862285,
          lng: -58.43288495767083,
        }}
      />
      <ProjectsFinanciacion
        title="UNIDADES FINANCIADAS"
        description="Mínimo anticipo y financiación en hasta 48 cuotas."
        colors={colors}
        image={
          "https://res.cloudinary.com/mosconi-digital/image/upload/v1681859014/Codevelopers/web/Set%20Palermo/2_j8ahs5.png"
        }
      />
    </div>
  );
};

export default SetPalermoPage;
